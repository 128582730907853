import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-overview"
export default class extends Controller {
  static targets = ["amount"]
  static values = { duration: Number }

  toggle(event) {
    this.amountTargets.forEach(element => {
      const range = this.calculateRange({ value: element.dataset.value, isMonthly: event.target.checked });
      this.animateValue(element, range.start, range.end);
    });
  }

  calculateRange({ value, isMonthly }) {
    const numValue = parseFloat(value);
    return isMonthly ? { start: numValue * 12, end: numValue } : { start: numValue, end: numValue * 12 };
  }

  animateValue(element, start, end) {
    let startTimestamp = null;
    const step = (timestamp) => {
      startTimestamp ||= timestamp
      const progress = Math.min((timestamp - startTimestamp) / this.durationValue, 1);
      element.innerHTML = this.formatCurrency(progress * (end - start) + start);

      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  formatCurrency(value) {
    return "$" + Math.round(value).toLocaleString("en-US", { maximumFractionDigits: 0 });
  }
}